import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Fieldset, TextInput } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/fieldset",
  "title": "Fieldset",
  "navTitle": "Fieldset"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Fieldset heading="Applicant information" style={{
        maxWidth: '400px'
      }} mdxType="Fieldset">
    <TextInput id="first-name" name="first-name" label="First name" mdxType="TextInput" />
    <TextInput id="last-name" name="last-name" label="Last name" style={{
          marginTop: '12px'
        }} mdxType="TextInput" />
    <TextInput id="social-security-number" name="social-security-number" label="Social security number" placeholder="Eg. 111299-1234" style={{
          marginTop: '12px'
        }} mdxType="TextInput" />
  </Fieldset>
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`The Fieldset component should only contain form elements such as input fields and form controls. See `}<InternalLink href="/patterns/forms/form-building" mdxType="InternalLink">{`HDS Form pattern documentation`}</InternalLink>{` for more information about available form elements.`}
        <ul parentName="li">
          <li parentName="ul">{`It is recommended that Fieldsets always contain at least two (2) form elements.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`As a rule of thumb, if a group of form elements require a heading, the usage of a Fieldset is recommended.`}
        <ul parentName="li">
          <li parentName="ul">{`For example, multiple input fields related to inputting user's address can be grouped together with a Fieldset with a heading "`}<em parentName="li">{`Address`}</em>{`".`}</li>
        </ul>
      </li>
      <li parentName="ul">{`A heading must be given to the Fieldset. The heading should be clear and describe the input group.`}
        <ul parentName="li">
          <li parentName="ul">{`A good heading describes why the form element inside the Fieldset are grouped together. For example, a heading "`}<em parentName="li">{`Guardian information`}</em>{`" tells the user that the following elements are meant to input personal information related to the child's guardian.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`HDS Fieldset does not style the content in any way.`}</strong>{` Depending on the layout of the service, you may have to add space between the inputs to distinguish them from each other.`}</li>
      <li parentName="ul">{`Avoid using Fieldsets if the form elements are only loosely related. Use a standard heading element instead.`}</li>
      <li parentName="ul">{`For grouping checkboxes or radio buttons, use `}<InternalLink href="/components/selection-group" mdxType="InternalLink">{`HDS Selection group component`}</InternalLink>{` instead.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The default HDS Fieldset is a visually light since it omits the borders that are present in the default HTML fieldset. It is well suited for forms with a relatively low amount of fields where input groups are easily perceivable even without Fieldset borders.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Fieldset heading="Applicant information" style={{
        maxWidth: '400px'
      }} mdxType="Fieldset">
    <TextInput id="first-name-1" name="first-name" label="First name" mdxType="TextInput" />
    <TextInput id="last-name-1" name="last-name" label="Last name" style={{
          marginTop: '12px'
        }} mdxType="TextInput" />
    <TextInput id="social-security-number-1" name="social-security-number" label="Social security number" placeholder="Eg. 111299-1234" style={{
          marginTop: '12px'
        }} mdxType="TextInput" />
  </Fieldset>
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-border",
      "style": {
        "position": "relative"
      }
    }}>{`With border`}<a parentName="h4" {...{
        "href": "#with-border",
        "aria-label": "with border permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`This Fieldset variant includes visual borders that are often present in the default HTML fieldsets. The borders help to distinguish groups from each other and they are useful when the form contains a large amount of form elements.`}</p>
    <p>{`Border variant is also useful when the form contains multiple form elements with a same label. For example, when the same contact information is asked for multiple different people in the same form.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Fieldset heading="Applicant information" border style={{
        maxWidth: '400px'
      }} mdxType="Fieldset">
    <TextInput id="first-name-2" name="first-name" label="First name" mdxType="TextInput" />
    <TextInput id="last-name-2" name="last-name" label="Last name" style={{
          marginTop: '12px'
        }} mdxType="TextInput" />
    <TextInput id="social-security-number-2" name="social-security-number" label="Social security number" placeholder="Eg. 111299-1234" style={{
          marginTop: '12px'
        }} mdxType="TextInput" />
  </Fieldset>
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      